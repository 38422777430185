<template>
  <jaya-connection-layout text="Aïe...">
    <h2 class="col-12 text-center text-danger">
      Erreur 404 !
    </h2>
    <p class="col-12 text-center pt-2">
      La page que vous cherchez n'a pas germé pour l'instant... <br>
      Hortilio s'excuse pour la gêne occasionnée. <br>
    </p>
  </jaya-connection-layout>
</template>

<script>
import JayaConnectionLayout from "@/components/global/JayaConnectionLayout";
import {BCol, BRow} from "bootstrap-vue";

export default {
  components: {
    JayaConnectionLayout,
    BRow,
    BCol,
  }
}
</script>
